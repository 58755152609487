<template>
  <div class="box">
    <div class="banner-img">
      <img class="cb-img" src="@/assets/certificate/banner1.png" alt="" />
    </div>
    <div class="search-box">
      <div class="search">
        <div class="input-box">
          <el-input
            v-model="keywords"
            placeholder="请输入教师姓名，学校名称关键字.."
            :clearable="true"
          ></el-input>
        </div>
        <div class="search-btn" @click="onSearch">搜索</div>
      </div>
    </div>
    <div class="history-box">
      <div class="history-content" v-if="history.length > 0">
        <div class="hs-title">
          <span class="title-name">搜索历史：</span>
          <div class="title-btn" @click="clearAll">
            <img class="btn-icon" src="@/assets/certificate/ic_3.png" alt="" />
            <span>全部清除</span>
          </div>
        </div>
        <div class="hs-list">
          <div
            class="hs-item"
            v-for="(item, index) in history"
            :key="index"
            @click="go(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="400px"
    >
      <span>请先注册或者登录后再进行查询！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onLogin">去登录</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      history: [],
      keywords: "",
      dialogVisible: false,
    };
  },
  mounted() {
    if (!this.userInfo.fk_user_id) {
      this.dialogVisible = true;
    }
    this.getTitle(4);
    let str = localStorage.getItem("history");
    if (str) {
      this.history = JSON.parse(str);
    }
  },
  methods: {
    onSearch() {
      if (this.keywords) {
        this.history.push(this.keywords);
        localStorage.setItem("history", JSON.stringify(this.history));
      }
      this.go(this.keywords);
    },
    clearAll() {
      this.history = [];
      localStorage.setItem("history", JSON.stringify(this.history));
    },
    onLogin() {
      this.$router.replace("/login");
    },
    go(name) {
      this.$router.push({
        name: "certificate_all",
        params: {
          name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 64px;
  border: none;
  font-size: 20px;
}
::v-deep .el-input__suffix {
  .el-icon-circle-close:before {
    position: relative;
    top: 4px;
    font-size: 20px;
  }
}
.banner-img {
  padding-top: 111px;
  width: 603px;
  .cb-img {
    width: 60%;
    height: auto;
  }
}
.search-box {
  padding-top: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .search {
    width: 930px;
    height: 64px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;
    .input-box {
      flex: 1;
      height: 100%;
      border-radius: 10px 0 0 10px;
      overflow: hidden;
    }
    .search-btn {
      width: 125px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0086ff;
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      border-radius: 0px 10px 10px 0px;
      cursor: pointer;
    }
  }
}
.history-box {
  width: 100%;
  padding: 80px 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  .history-content {
    width: 930px;
    display: flex;
    flex-direction: column;
    .hs-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .title-name {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
      }
      .title-btn {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        display: flex;
        align-items: center;
        .btn-icon {
          width: 18px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
    .hs-list {
      width: 100%;
      display: flex;
      .hs-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 10px;
        border: 1px solid #999;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #999;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
